import React, { useContext, useEffect, useState } from 'react';
import { MainContainer } from '../../components/MainContainer';

import { FilterComponent } from './components/filter/FiltersComponent';

import './components/table/index.css';

import { useReportCatalog } from '../../hooks/reports/useReportCatalogs';
import { StoreContext } from '../../business/Provider';
import {
	downloadFileBase64,
	generateArrayToIds,
	generateArrayToIdsInt,
	typeIncidences,
} from '../../utils/utils';
import { TableQueriesComponent } from './components/table/TableQueriesComponent';
import { IconsPanelControl } from '../../components/IconsPanelControl';
import { Button } from 'primereact/button';

import { LOADING_OFF, LOADING_ON } from '../../business/constants';
import {
	dataToExportChecks,
	generateDynamicColumns,
	getDateTime,
	addMissingDates,
	dataToExportIncidences,
} from './components/utils';

import { useQueries } from '../../hooks/queries/useQueries';
import { SearchComponent } from '../../components/SearchComponent';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from '../../components/toast';

import useReload from './../../hooks/useReload';
import { GeneralNames } from '../../namesConstants/names';

import { FilterContext } from '../../context/wrapperContext/queries/FilterContext';
import { TwoContainerComponent } from '../../components/Containers/TwoRowContainer';
export const QueriesComponent = () => {
	const { getCatalogs } = useReportCatalog();
	const { getQueries } = useQueries();
	const {
		typeQuerySelect,
		clearFilters,
		typePeriodSelect,
		startDate,
		endDate,
		workCentersSelect,
		shiftSelect,
		departmentSelect,
		positionSelect,
		collaboratorsSelect,
		incidentTypeSelect,
		filterTypeQuerySelect,
		setFilterTypeQuerySelect,
		showColumnsDate,
		onChangeShowColumDate,
	} = useContext(FilterContext);

	const {
		getCatalogReportState,
		getQueriesState,
		dispatchLoading,
		loadingState,
		companyState,
	} = useContext(StoreContext);
	const [tableDataQuery, setTableDataQuery] = useState(null);
	const [dynamicColumns, setDynamicColumns] = useState([]); //valores que vas a utilizar para la exportacion
	const [sort, setSort] = useState([{ field: 'Code', order: -1 }]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [periodType, setPeriodTypeData] = useState([]);
	const [collaborators, setCollaborators] = useState([]);
	const [positions, setPositions] = useState([]);
	const [workCenters, setWorkCenters] = useState([]);
	const [workShiftTypes, setWorkShiftTypes] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [incidentTypes, setIncidentTypes] = useState([]);

	const [nameQuery, setNameQuery] = useState('');
	const [totalizer, setTotalizer] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);

	useEffect(() => {
		setTableDataQuery(null);
		//clearFilters();
		setUpdateTable(!updateTable);
	}, []);

	useReload([getCatalogs, getReloadData]);

	async function getReloadData() {
		return await getDataQueries(
			filterTypeQuerySelect,
			startDate,
			endDate,
			typePeriodSelect,
			workCentersSelect,
			shiftSelect,
			departmentSelect,
			positionSelect,
			collaboratorsSelect,
			incidentTypeSelect
		);
	}

	useEffect(() => {
		const typeIncidences =
			incidentTypeSelect !== null && incidentTypeSelect.length > 0
				? generateArrayToIdsInt(incidentTypeSelect)
				: null;

		const columns = generateDynamicColumns(
			showColumnsDate,
			startDate,
			endDate,
			filterTypeQuerySelect,
			totalizer,
			typeIncidences
		);

		setDynamicColumns(columns);
		setUpdateTable(!updateTable);
	}, [showColumnsDate, totalizer]);

	useEffect(() => {
		if (getQueriesState.queries !== null) {
			const { tableData, columns, nameScreen } = getQueriesState.queries;

			setDynamicColumns(columns);
			setTableDataQuery(tableData); // se debe cambiar este campo cuando ya obtengamos datos de la tabla
			setNameQuery(nameScreen);
			//setShowColumnsDate(false);
		} else {
			setDynamicColumns([]);
			setTableDataQuery(null); // se debe cambiar este campo cuando ya obtengamos datos de la tabla
			setNameQuery('');
			//	setShowColumnsDate(false);
		}
	}, [getQueriesState]);

	useEffect(() => {
		if (getCatalogReportState.catalogs !== null) {
			const { data } = getCatalogReportState.catalogs;

			setPeriodTypeData(data.periodType);
			setCollaborators(data.collaborator);
			setPositions(data.position);
			setWorkCenters(data.workcenter);
			setWorkShiftTypes(data.workshiftdetail);
			setDepartments(data.department);
			setIncidentTypes(data.incidentType);
		}
	}, [getCatalogReportState]);

	async function onSubmitFilter({
		typeQuery,
		startDate,
		endDate,
		TypePeriods,
		workCenters,
		shifts,
		departments,
		positions,
		collaborators,
		typeIncidents,
	}) {
		setFilterTypeQuerySelect(typeQuerySelect);

		return getDataQueries(
			typeQuery,
			startDate,
			endDate,
			TypePeriods,
			workCenters,
			shifts,
			departments,
			positions,
			collaborators,
			typeIncidents
		);
	}

	async function getDataQueries(
		typeQueryData,
		startDateData,
		endDateData,
		TypePeriodsData,
		workCentersData,
		shiftsData,
		departmentsData,
		positionsData,
		collaboratorsData,
		typeIncidentsData
	) {
		//typeIncidences
		let _typePeriods =
			periodType.length === TypePeriodsData.length
				? []
				: generateArrayToIds(TypePeriodsData);

		let _workCenters =
			workCenters.length === workCentersData.length
				? []
				: generateArrayToIds(workCentersData);
		let _workShifts =
			workShiftTypes.length === shiftsData.length
				? []
				: generateArrayToIds(shiftsData);
		let _departmentsData =
			departments.length === departmentsData.length
				? []
				: generateArrayToIds(departmentsData);

		let _jobsPosition =
			positions.length === positionsData.length
				? []
				: generateArrayToIds(positionsData);
		let _collaborators =
			collaborators.length === collaboratorsData.length
				? []
				: generateArrayToIds(collaboratorsData);

		const dataToSend = {
			startDate: startDateData,
			endDate: endDateData,
			typePeriods: _typePeriods,
			workCenters: _workCenters,
			workShifts: _workShifts,
			departments: _departmentsData,
			jobsPosition: _jobsPosition,
			collaborators: _collaborators,
		};
		let dataSendChecks = null;
		let dataToSendIncidences = null;
		if (typeQueryData.code === 'I') {
			let _typeIncidence =
				typeIncidences.length === typeIncidentsData.length
					? []
					: generateArrayToIdsInt(typeIncidentsData);

			dataToSendIncidences = {
				...dataToSend,
				typeIncidents: _typeIncidence,
			};
		} else {
			dataSendChecks = dataToSend;
		}
		return await getQueries(
			typeQueryData?.code === 'I' ? dataToSendIncidences : dataSendChecks,
			typeQueryData,
			totalizer
		);
	}

	const leftComponent = () => {
		return (
			<FilterComponent
				onSubmitFilter={onSubmitFilter}
				periodType={periodType}
				collaborators={collaborators}
				positions={positions}
				workCenters={workCenters}
				workShiftTypes={workShiftTypes}
				departments={departments}
			/>
		);
	};

	async function toExcelExport() {
		if (tableDataQuery !== null && tableDataQuery?.length !== 0) {
			const copyTableDataQuery = JSON.parse(JSON.stringify(tableDataQuery));
			const addedDates = addMissingDates(
				copyTableDataQuery,
				startDate,
				endDate
			);
			const body =
				typeQuerySelect?.code === 'I'
					? dataToExportIncidences(
							showColumnsDate ? dynamicColumns?.open : dynamicColumns?.close,
							companyState,
							addedDates,
							incidentTypes
					  )
					: dataToExportChecks(
							showColumnsDate ? dynamicColumns?.open : dynamicColumns?.close,
							companyState,
							addedDates
					  );

			dispatchLoading({ type: LOADING_ON });
			await downloadFileBase64(
				body,
				'xlsx',
				typeQuerySelect?.code === 'I'
					? `Incidencias_${getDateTime()}`
					: `Checadas_${getDateTime()}`
			)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			return Toast('warning', 'No hay datos para exportar');
		}
	} //funcion para exportacion

	const iconsData = [
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: toExcelExport,
		},
	];

	function clearTextLabel() {
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	}

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	const rightComponent = () => {
		return (
			<div className='h-full flex flex-col w-full ml-1 border rounded-md shadow-2xl'>
				<div className='flex w-full h-12 flex-row '>
					<div className='flex h-full w-1/3 items-center'>
						<h1 className='text-2xl font-karla font-bold ml-2'>{nameQuery}</h1>
					</div>
					<div className='flex h-full w-1/3 p-1 '>
						<SearchComponent
							clearTextLabel={clearTextLabel}
							onChange={onGlobalFilterChange}
							value={globalFilterValue}
						/>
					</div>
					<div className='flex h-full w-1/3'>
						{filterTypeQuerySelect.code === 'I' ? (
							<div className='flex w-4/5 p-1 flex-row'>
								<div className='flex h-full w-1/2 flex-col px-1'>
									<Button
										title={
											showColumnsDate ? 'Ver en Resumen' : 'Ver en Detalle'
										}
										onClick={() => onChangeShowColumDate(!showColumnsDate)}
										label={showColumnsDate ? ' Ver Resumen' : ' Ver Detalle'}
										//	icon={showColumnsDate ? 'pi pi-eye-slash' : 'pi pi-eye'}
										className='!w-full !text-xs !truncate'
										disabled={
											tableDataQuery === null || tableDataQuery.length === 0
										}
									/>
								</div>
								<div className='flex h-full w-1/2 px-1'></div>
							</div>
						) : (
							<div className='flex w-4/5 p-1 '>
								<Button
									onClick={() => onChangeShowColumDate(!showColumnsDate)}
									label={showColumnsDate ? 'Ver resumen' : 'Ver detalle'}
									icon={showColumnsDate ? 'pi pi-eye-slash' : 'pi pi-eye'}
									className='!w-full !text-xs !h-full !truncate'
									disabled={
										tableDataQuery === null || tableDataQuery.length === 0
									}
								/>
							</div>
						)}
						<div className='flex w-1/5 p-1'>
							<IconsPanelControl iconsData={iconsData} />
						</div>
					</div>
				</div>
				<div className='flex w-full h-full flex-grow  relative p-1'>
					{tableDataQuery !== null ? (
						<TableQueriesComponent
							tableDataQuery={tableDataQuery}
							dynamicColumns={dynamicColumns}
							filters={filters}
							sort={sort}
							setSort={setSort}
							keyTable={updateTable}
						/>
					) : null}
				</div>
			</div>
		);
	};
	const renderBodyMainContainer = () => {
		return (
			<TwoContainerComponent
				leftComponent={leftComponent()}
				rightComponent={rightComponent()}
			/>
		);
	};

	return (
		<MainContainer cancelTittle iNeedFullLoading={loadingState}>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				{renderBodyMainContainer()}
			</div>
		</MainContainer>
	);
};
