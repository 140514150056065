import React from 'react';
export const ContentRadio = ({
	name,
	id1,
	radio1,
	labelRadio1,
	id2,
	radio2,
	labelRadio2,
}) => {
	return (
		<div className='flex w-full h-20 flex-col'>
			<div className='flex w-full h-[10%]'>
				<h1>{name}</h1>
			</div>
			<div className='flex w-full h-[90%] flex-col pl-2 pt-2'>
				<div className='flex w-full h-1/2 align-items-center justify-start content-center items-center'>
					{radio1}
					<label htmlFor={id1} className='ml-2'>
						{labelRadio1}
					</label>
				</div>
				<div className='flex w-full h-1/2 align-items-center justify-start content-center items-center'>
					{radio2}
					<label htmlFor={id2} className='ml-2'>
						{labelRadio2}
					</label>
				</div>
			</div>
		</div>
	);
};
