import { TableNames } from '../../../../namesConstants/names';

export const headersTranslations = [
	'Cod Empleado',
	'Nombre',
	'Tipo de periodo',
	'Puesto',
	'Departamento',
	'Turno',
	'Lider',
];

export const headers = [
	'formattedCode',
	'fullName',
	'periodTypeName',
	'jobPositionaName',
	'jobDepartmentName',
	'shift',
	'leaderName',
];

export const headerClassName =
	'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !justify-center  ';
export const bodyClassName = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-left`;
export const bodyClassNameCenter = `!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-center`;

export const tableColumnsData = [
	{
		field: 'formattedCode',
		label: 'Código',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '120px',
		body: false,
	},
	{
		field: 'fullName',
		label: 'Nombre',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '200px',
		body: false,
	},
	{
		field: 'periodTypeName',
		label: 'Tipo de periodo',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '150px',
		body: false,
	},
	{
		field: 'jobPositionaName',
		label: 'Puesto',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '150px',
		body: false,
	},
	{
		field: 'jobDepartmentName',
		label: 'Departamento',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '200px',
		body: false,
	},
	{
		field: 'shift',
		label: 'Turno',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '150px',
		body: true,
	},
	{
		field: 'leaderName',
		label: 'Líder',
		sortable: true,
		filter: true,
		showFilterMenu: true,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '150px',
		body: false,
	},
	{
		field: 'openWorkArea',
		label: 'Ubicación',
		sortable: false,
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '70px',
		body: true,
	},
	{
		field: 'actions',
		label: TableNames.TableActions,
		sortable: false,
		filter: false,
		showFilterMenu: false,
		showFilterOperator: false,
		showFilterMatchModes: false,
		filterPlaceHolder: 'Buscar',
		height: '100px',
		body: true,
		bodyClassName: bodyClassNameCenter,
	},
];
