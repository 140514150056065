import React, { Fragment } from 'react';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import { IconComponent } from '../IconComponent';
export const SidebarCatalogsComponent = ({
	isOpen,
	setIsOpen,
	currentTap,
	setCurrentTap,
	setItemSelected,
	Data = [],
}) => {
	return (
		<div
			className={
				'flex   bg-white  border drop-shadow-2xl flex-col font-karla ' +
				(isOpen
					? ' sm:w-[20%]  md:w-[20%]  lg:w-[16%] xl:w-[16%] 2xl:w-[11%]'
					: ' w-16')
			}>
			<div className='flex content-end justify-end item-end'>
				{isOpen ? (
					<TiChevronLeft
						onClick={() => setIsOpen(false)}
						className={'text-2xl text-light-ShadowPCL004'}
					/>
				) : (
					<TiChevronRight
						onClick={() => setIsOpen(true)}
						className={'text-2xl text-light-ShadowPCL004'}
					/>
				)}
			</div>
			{Data.map((item, index) => {
				let key = index.toString();
				return (
					<Fragment key={key}>
						{item.children.map((itemChildren, keyChildren) => {
							const isCurrenTap =
								currentTap?.id === itemChildren.id
									? ' border bg-[#99bfe3]  text-[#005eb8]  '
									: '  hover:bg[#99bfe3] ';
							return (
								<div
									key={itemChildren.name}
									title={itemChildren.title}
									onClick={() => {
										setItemSelected(itemChildren.id);
										setCurrentTap(itemChildren);
									}}
									className={
										'flex w-full h-6 drop-shadow-2xl flex-row  cursor-pointer hover:bg-slate-200 ' +
										isCurrenTap
									}>
									{
										<div
											className={
												'flex h-full  justify-center content-center items-center cursor-pointer ' +
												(isOpen ? ' w-1/5 ' : ' w-full ')
											}>
											<IconComponent
												iconName={itemChildren.icon}
												className={'text-2xl text-light-ShadowPCL004 '}
											/>
										</div>
									}
									{isOpen && (
										<div className='flex h-full w-4/5 justify-start content-center items-center cursor-pointer'>
											<label
												className='ml-2 text-xs font-bold text-light-ShadowPCL004 cursor-pointer'
												htmlFor=''>
												{itemChildren.title}
											</label>
										</div>
									)}
								</div>
							);
						})}
					</Fragment>
				);
			})}
		</div>
	);
};
