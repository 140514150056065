import React, { useContext } from 'react';
import { OrganizationChartContext } from '../../Services/OrganizationChart/OrganizationCharService';
import { MainContainer } from '../../components/MainContainer';
import { OrgChartTableComponent } from './components/Table/OrgCharTable';
import { SidebarOrgChartComponent } from './components/Sidebar/SidebarOrgChart';
import { DialogOrgChart } from './components/Dialog';
import { StoreContext } from '../../business/Provider';
import { ModalErrors } from './components/ModalErrors';
import { CustomAlertDialog } from '../../components/Dialogs/CustomAlertDialog';
import {
	GeneralNames,
	OrganizationChartNames,
} from '../../namesConstants/names';
import { TwoContainerComponent } from '../../components/Containers/TwoRowContainer';
export const OrganizationChart = () => {
	const service = useContext(OrganizationChartContext);
	const contextLoading = useContext(StoreContext);

	const leftComponent = () => {
		return <SidebarOrgChartComponent service={service} />;
	};
	const renderBodyMain = () => {
		return <OrgChartTableComponent service={service} />;
	};

	const renderBodyMainContainer = () => {
		return (
			<TwoContainerComponent
				leftComponent={leftComponent()}
				rightComponent={renderBodyMain()}
			/>
		);
	};
	return (
		<MainContainer loadingForTable={service.loadingTable} cancelTittle>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				{renderBodyMainContainer()}
				<DialogOrgChart service={service} contextLoading={contextLoading} />
				<ModalErrors service={service} />
				<CustomAlertDialog
					title={OrganizationChartNames.DeleteLeaderModalTitle}
					setOpen={() => service.onCloseModalDelete()}
					open={service.openModalDeleted}
					message={service.textDeleteModal}
					cancelLabel={GeneralNames.GeneralCancel}
					agreeLabel={GeneralNames.GeneralAccept}
					agreeIcon='pi pi-check'
					onConfirmFunction={() => service.deleteLeaderOrgChart()}
					style={{ width: '30vw' }}
				/>
			</div>
		</MainContainer>
	);
};
