import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { categoriesRadios, getDropsMultiData } from './utils';
import { MultiSelectComponent } from '../../../../components/MultiSelect/MultiSelectComponent';
import { Button } from 'primereact/button';
import { OrganizationChartNames } from '../../../../namesConstants/names';
import PropTypes from 'prop-types';
export const SidebarOrgChartComponent = ({ service }) => {
	const dataDrops = getDropsMultiData(service);

	const renderMultiSelect = (
		id,
		name,
		label,
		placeholder,
		optionLabel,
		value,
		options,
		onChange,
		setState,
		filter
	) => {
		return (
			<div key={id} className='flex w-full mt-1'>
				<MultiSelectComponent
					label={label}
					name={name}
					placeholder={placeholder}
					optionLabel={optionLabel}
					value={value}
					options={options}
					onChange={onChange}
					setState={setState}
					title={label}
					filter={filter}
					otherPlaceHolder={placeholder}
				/>
			</div>
		);
	};

	return (
		<div className='flex  w-full h-full  flex-col'>
			<div className='flex w-full h-full flex-col px-2 '>
				<div className='flex w-full flex-col mt-1 h-16 '>
					{categoriesRadios.map((category) => {
						return (
							<div
								key={category.key}
								className='field-radiobutton h-8  justify-center truncate'>
								<RadioButton
									inputId={category.key}
									name='category'
									value={category}
									onChange={(e) => service.handleInvitedCollaborator(e.value)}
									checked={service.invitedCollaborator.key === category.key}
								/>
								<label
									title={category.name}
									className='truncate ml-1 font-semibold text-sm lg:text-xs '
									htmlFor={category.key}>
									{category.name}
								</label>
							</div>
						);
					})}
				</div>
				<div className='flex w-full h-full flex-col mt-1 relative'>
					<div className='inset-0 absolute overflow-auto'>
						{dataDrops.map((item, index) => {
							return renderMultiSelect(
								index,
								item.name,
								item.label,
								item.placeholder,
								item.optionLabel,
								item.value,
								item.options,
								item.onChange,
								item.setState,
								item.filter
							);
						})}
					</div>
				</div>
			</div>
			<div className='flex w-full  h-16  '>
				<div className='flex w-full h-full  flex-row py-1 pl-[4px] pr-[4px] content-center items-center justify-between'>
					<Button
						label='Limpiar'
						className='!flex !w-28 !h-full !bg-white !text-black !text-xs lg:!w-[68px]'
						aria-label='cancel'
						onClick={() => service.clearFilters()}
					/>
					<Button
						label='Consultar'
						className='!flex !w-28 !h-full !text-xs lg:!w-[68px]'
						aria-label='Submit'
						type='submit'
						onClick={() => service.getCollaboratorsOrgChart()}
					/>
				</div>
			</div>
		</div>
	);
};

SidebarOrgChartComponent.propTypes = {
	service: PropTypes.object,
};
