import { createContext, useState } from 'react';
import { OperationNames } from '../../../namesConstants/names';
import useOperation from '../../../hooks/catalogs/Operation/useOperation';

export const EmployeeManagementFilterContext = createContext();

export const EmployeeManagementFilterProvider = ({ children }) => {
	const [typePeriodSelected, setTypePeriodSelected] = useState([]);
	const [periodsTypesData, setPeriodsTypesData] = useState([]);
	const [invitedCollaborator, setInvitedCollaborator] = useState({
		key: '1',
		name: OperationNames.AllCollaborators,
		value: false,
	});
	const [departmentsSelected, setDepartmentsSelected] = useState([]);
	const [departmentsData, setDepartmentsData] = useState([]);
	const [positionsSelected, setPositionsSelected] = useState([]);
	const [positionsData, setPositionsData] = useState([]);
	const [shiftSelected, setShiftSelected] = useState([]);
	const [shiftData, setShiftData] = useState([]);
	const [workCenterSelected, setWorkCenterSelected] = useState([]);
	const [workCenterData, setWorkCenterData] = useState([]);
	const [actualLeaderSelected, setActualLeaderSelected] = useState([]);
	const [actualLeaderData, setActualLeaderData] = useState([]);
	const [collaboratorSelected, setCollaboratorSelected] = useState([]);
	const [collaboratorData, setCollaboratorData] = useState([]);
	const { dataFilterOperations, getCollaboratorsOnOperation } = useOperation();
	function onHandleTypePeriods(data) {
		setTypePeriodSelected(data.value);
	}

	function onHandleDepartments(data) {
		setDepartmentsSelected(data.value);
	}

	function onHandlePositions(data) {
		setPositionsSelected(data.value);
	}
	function onHandleShifts(data) {
		setShiftSelected(data.value);
	}

	function onHandleWorkCenters(data) {
		setWorkCenterSelected(data.value);
	}

	function onHandleActualLeader(data) {
		setActualLeaderSelected(data.value);
	}

	function onHandleCollaborators(data) {
		setCollaboratorSelected(data.value);
	}

	async function handleInvitedCollaborator(item) {
		setInvitedCollaborator(item);
		return dataFilterOperations(item.value);
	}

	function clearFilters() {
		setTypePeriodSelected([]);
		setDepartmentsSelected([]);
		setPositionsSelected([]);
		setShiftSelected([]);
		setWorkCenterSelected([]);
		setActualLeaderSelected([]);
		setCollaboratorSelected([]);
		setInvitedCollaborator({
			key: '1',
			name: OperationNames.AllCollaborators,
			value: false,
		});
	}

	function mapToId(_data, property = 'id') {
		return _data.length !== 0 ? _data.map((item) => item[property]) : [];
	}
	function createDataBase64() {
		const data = {
			periodType:
				mapToId(typePeriodSelected).length === periodsTypesData.length
					? []
					: mapToId(typePeriodSelected),
			department:
				mapToId(departmentsSelected).length === departmentsData.length
					? []
					: mapToId(departmentsSelected),
			position:
				mapToId(positionsSelected).length === positionsData.length
					? []
					: mapToId(positionsSelected),
			shift:
				mapToId(shiftSelected).length === shiftData.length
					? []
					: mapToId(shiftSelected),
			workCenter:
				mapToId(workCenterSelected).length === workCenterData.length
					? []
					: mapToId(workCenterSelected),
			actualLeader: mapToId(actualLeaderSelected),
			employee:
				mapToId(collaboratorSelected).length === collaboratorData.length
					? []
					: mapToId(collaboratorSelected),
			onlyInvited: invitedCollaborator?.value || false,
		};
		return Buffer.from(JSON.stringify(data)).toString('base64');
	}
	async function getDataByFilter() {
		const filter = createDataBase64();
		return await getCollaboratorsOnOperation(filter);
	}
	return (
		<EmployeeManagementFilterContext.Provider
			key={'EmployeeManagementFilter'}
			value={{
				departmentsSelected,
				onHandleTypePeriods,
				typePeriodSelected,
				periodsTypesData,
				invitedCollaborator,
				setTypePeriodSelected,
				departmentsData,
				setDepartmentsSelected,
				onHandleDepartments,
				positionsSelected,
				positionsData,
				setPositionsSelected,
				onHandlePositions,
				shiftSelected,
				setShiftSelected,
				shiftData,
				onHandleShifts,
				workCenterSelected,
				setWorkCenterSelected,
				workCenterData,
				onHandleWorkCenters,
				actualLeaderSelected,
				setActualLeaderSelected,
				actualLeaderData,
				onHandleActualLeader,
				collaboratorSelected,
				setCollaboratorSelected,
				collaboratorData,
				onHandleCollaborators,
				clearFilters,
				getDataByFilter,
				createDataBase64,
				setPeriodsTypesData,
				setInvitedCollaborator,
				setDepartmentsData,
				setPositionsData,
				setShiftData,
				setWorkCenterData,
				setActualLeaderData,
				setCollaboratorData,
				handleInvitedCollaborator,
			}}>
			{children}
		</EmployeeManagementFilterContext.Provider>
	);
};
