import React, { useState } from 'react';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

export const TwoContainerComponent = ({ leftComponent, rightComponent }) => {
	const [isOpen, setIsOpen] = useState(true);
	const renderLeftContainer = () => {
		return (
			<div
				className={
					'flex   bg-white  border drop-shadow-2xl rounded-md flex-col h-full' +
					(isOpen
						? ' sm:w-[20%]  md:w-[20%]  lg:w-[18%] xl:w-[16%] 2xl:w-[16%]'
						: ' w-16')
				}>
				<div className='flex content-end justify-end item-end'>
					{isOpen ? (
						<TiChevronLeft
							onClick={() => setIsOpen(false)}
							className={'text-2xl text-light-ShadowPCL004'}
						/>
					) : (
						<TiChevronRight
							onClick={() => setIsOpen(true)}
							className={'text-2xl text-light-ShadowPCL004'}
						/>
					)}
				</div>
				{isOpen === true ? <>{leftComponent} </> : null}
			</div>
		);
	};

	const renderRightContainer = () => {
		return (
			<div className='flex flex-grow h-full flex-col'>{rightComponent}</div>
		);
	};

	return (
		<div className='flex w-full h-full flex-row relative rounded-md'>
			{renderLeftContainer()}
			{renderRightContainer()}
		</div>
	);
};
