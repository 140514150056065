import React, { useContext, useEffect, useState } from 'react';
import { MainContainer } from '../../../components/MainContainer';

import { initialStateFilterOperationCollaborators } from '../../Invitations/const';
import { OperationNames } from '../../../namesConstants/names';
import { TableOperation } from './components/TableOperation';
import { StoreContext } from '../../../business/Provider';
import useReload from '../../../hooks/useReload';
import useOperation from '../../../hooks/catalogs/Operation/useOperation';

import { ToolbarTwoColumns } from './components/ToolbarTwoColumns';
import { WorkLocationCollaboratorDialog } from './components/WorkLocationCollaboratorDialog';
import { WorkShiftCollaboratorDialog } from './components/WorkShiftCollaboratorDialog';
import useWorkCenter from '../../../hooks/catalogs/WorkCenter/useWorkCenter';
import { addLocale } from 'primereact/api';
import { primeReactTranslations } from '../../../primeReactTranslateOptions';
import { EmployeeMaintenanceServiceContext } from '../../../Services/Operations/employeeMaintenance/EmployeeMaintenanceServiceManager';
import { ModalErrors } from './components/ModalErrors';

import { EmployeeManagementFilterContext } from './../../../context/wrapperContext/queries/EmployeeManagementFilterContext';
import { SidebarEmployeeManagementComponent } from './components/Sidebar/index';
import { TwoContainerComponent } from '../../../components/Containers/TwoRowContainer';
export const EmployeeManagement = () => {
	const serviceManager = useContext(EmployeeMaintenanceServiceContext);
	const filterManager = useContext(EmployeeManagementFilterContext);
	const [idPersoniaCollaborator, setIdPersoniaCollaborator] =
		useState(undefined);
	const [filters, setFilters] = useState(
		initialStateFilterOperationCollaborators
	);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [isHomeOffice, setIsHomeOffice] = useState(false);
	const [isMassive, setIsMassive] = useState(false);
	const [collaborators, setCollaborators] = useState([]);
	const [collaborator, setCollaborator] = useState([]);
	const [title, setTitle] = useState('');
	const [selectedCollaborators, setSelectedCollaborators] = useState([]);
	const [openWorkCenterLocationDialog, setOpenWorkCenterLocationDialog] =
		useState(false);
	const [openWorkShifDialog, setOpenWorkShifDialog] = useState(false);
	const [workCenters, setWorkCenters] = useState([]);
	const [workShifts, setWorkShifts] = useState([]);
	const [workShiftSelected, setWorkShiftSelected] = useState(undefined);
	const [collaboratorWorkShiftDetialId, setCollaboratorWorkShiftDetialId] =
		useState(undefined);
	const [openDialogDeleteSchedule, setOpenDialogDeleteSchedule] =
		useState(false);
	const [viewHistorical, setViewHistorical] = useState(false);

	useEffect(() => {
		addLocale('es', primeReactTranslations);
	}, []);

	const {
		getCollaboratorsOnOperation,
		getWorkCenterLocationCollaboratorsOnOperation,
		postWorkCenterLocationCollaboratorsOnOperation,
		postWorkShiftMassiveCollaboratorsOnOperation,
		postWorkShiftSingleCollaboratorsOnOperation,
		getWorkShiftSingleCollaboratorsOnOperation,
		deleteWorkShiftSingleCollaboratorsOnOperation,
		updateCollaboratorOpenWorkArea,
	} = useOperation();

	const { getWorkCenters } = useWorkCenter();

	useReload([getCollaboratorsOnOperation]);

	const {
		getOperationCollaboratorsState,
		loadingState,
		getOperationWorkCenterLocationCollaboratorsState,
	} = useContext(StoreContext);

	useEffect(() => {
		if (
			getOperationCollaboratorsState.loading === false &&
			getOperationCollaboratorsState.collaborators !== undefined &&
			getOperationCollaboratorsState.collaborators !== null
		) {
			const _result = getOperationCollaboratorsState.collaborators?.results;
			const _selected = _result.filter((r) => {
				return selectedCollaborators.some((s) => s.id === r.id);
			});
			setCollaborators(_result);
			setSelectedCollaborators(_selected);
		}
	}, [getOperationCollaboratorsState.collaborators]);

	useEffect(() => {
		if (
			getOperationWorkCenterLocationCollaboratorsState.loading === false &&
			getOperationWorkCenterLocationCollaboratorsState.workCenters !==
				undefined &&
			getOperationWorkCenterLocationCollaboratorsState.workCenters !== null
		) {
			const _result =
				getOperationWorkCenterLocationCollaboratorsState.workCenters?.results;
			setWorkCenters(_result);
		}
	}, [getOperationWorkCenterLocationCollaboratorsState.workCenters]);

	useEffect(() => {
		clearTextLabel();
	}, []);

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	};

	const clearTextLabel = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	};
	const renderHeaderGrid = () => {
		return (
			<div className='w-full flex flex-col !h-[60px]'>
				<ToolbarTwoColumns
					hidden={true}
					onChange={onGlobalFilterChange}
					placeholder={OperationNames.PlaceHolderSearch}
					value={globalFilterValue}
					clearTextLabel={clearTextLabel}
					filters={collaborators}
					selectedCollaborators={selectedCollaborators}
					setOpenWorkShifDialog={setOpenWorkShifDialog}
					setIsMassive={setIsMassive}
					filter={filters}
					filterManager={filterManager}
				/>
			</div>
		);
	};
	const renderTable = () => {
		return (
			<TableOperation
				setWorkShiftSelected={setWorkShiftSelected}
				filters={filters}
				list={collaborators}
				setFilter={setFilters}
				setSelectedCollaborators={setSelectedCollaborators}
				selectedCollaborators={selectedCollaborators}
				setOpenWorkCenterLocationDialog={setOpenWorkCenterLocationDialog}
				setIdPersoniaCollaborator={setIdPersoniaCollaborator}
				setIsHomeOffice={setIsHomeOffice}
				setTitle={setTitle}
				setOpenWorkShifDialog={setOpenWorkShifDialog}
				setIsMassive={setIsMassive}
				getWorkCenterLocationCollaboratorsOnOperation={
					getWorkCenterLocationCollaboratorsOnOperation
				}
				loading={
					!loadingState &&
					getOperationCollaboratorsState.loading &&
					!openWorkCenterLocationDialog
				}
				updateCollaboratorOpenWorkArea={updateCollaboratorOpenWorkArea}
				collaborator={collaborator}
				setCollaborator={setCollaborator}
			/>
		);
	};

	const renderBodyMain = () => {
		return (
			<div className=' h-full flex flex-col w-full ml-1 border rounded-md shadow-2xl'>
				{renderHeaderGrid()}
				{renderTable()}
			</div>
		);
	};

	const onCloseWorkCenters = () => {
		setOpenWorkCenterLocationDialog(false);
		setIdPersoniaCollaborator(undefined);
		setWorkCenters([]);
	};

	const onCloseWorkShift = () => {
		setOpenWorkShifDialog(false);
	};

	const leftComponent = () => {
		return <SidebarEmployeeManagementComponent service={filterManager} />;
	};

	const renderBodyMainContainer = () => {
		return (
			<TwoContainerComponent
				leftComponent={leftComponent()}
				rightComponent={renderBodyMain()}
			/>
		);
	};
	return (
		<MainContainer cancelTittle>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				{renderBodyMainContainer()}
				<ModalErrors service={serviceManager} />
				<WorkLocationCollaboratorDialog
					title={title}
					openWorkCenterLocationDialog={openWorkCenterLocationDialog}
					onClose={onCloseWorkCenters}
					idPersoniaCollaborator={idPersoniaCollaborator}
					isHomeOffice={isHomeOffice}
					workcenters={workCenters}
					postWorkCenterLocationCollaboratorsOnOperation={
						postWorkCenterLocationCollaboratorsOnOperation
					}
					getWorkCenters={getWorkCenters}
				/>
				<WorkShiftCollaboratorDialog
					openWorkShifDialog={openWorkShifDialog}
					onClose={onCloseWorkShift}
					isMassive={isMassive}
					selectedCollaborators={selectedCollaborators}
					setSelectedCollaborators={setSelectedCollaborators}
					idPersoniaCollaborator={idPersoniaCollaborator}
					setIdPersoniaCollaborator={setIdPersoniaCollaborator}
					workShifts={workShifts}
					setWorkShifts={setWorkShifts}
					setWorkShiftSelected={setWorkShiftSelected}
					workShiftSelected={workShiftSelected}
					setCollaboratorWorkShiftDetialId={setCollaboratorWorkShiftDetialId}
					collaboratorWorkShiftDetialId={collaboratorWorkShiftDetialId}
					openDialogDeleteSchedule={openDialogDeleteSchedule}
					setOpenDialogDeleteSchedule={setOpenDialogDeleteSchedule}
					getWorkShiftSingleCollaboratorsOnOperation={
						getWorkShiftSingleCollaboratorsOnOperation
					}
					postWorkShiftMassiveCollaboratorsOnOperation={
						postWorkShiftMassiveCollaboratorsOnOperation
					}
					postWorkShiftSingleCollaboratorsOnOperation={
						postWorkShiftSingleCollaboratorsOnOperation
					}
					deleteWorkShiftSingleCollaboratorsOnOperation={
						deleteWorkShiftSingleCollaboratorsOnOperation
					}
					collaborator={collaborator}
					setCollaborator={setCollaborator}
					viewHistorical={viewHistorical}
					setViewHistorical={setViewHistorical}
				/>
			</div>
		</MainContainer>
	);
};
