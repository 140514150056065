import React, { useContext } from 'react';

import {
	downloadFileExcel,
	getFileNameWithDateTime,
	regexName,
} from '../../../../utils/utils';
import { GeneralNames } from '../../../../namesConstants/names';

import { StoreContext } from '../../../../business/Provider';
import { LOADING_OFF, LOADING_ON } from '../../../../business/constants';
import { Toast } from '../../../../components/toast';

import { SearchComponent } from '../../../../components/SearchComponent';
import { IconsPanelControl } from '../../../../components/IconsPanelControl';
import { useExport } from '../../../../hooks/export/useExport';
export const ToolbarTwoColumns = ({
	onChange,
	placeholder,
	filters,
	selectedCollaborators,
	value = '',
	clearTextLabel = () => {},
	setOpenWorkShifDialog,
	setIsMassive,
	filter,
	filterManager,
}) => {
	const { dispatchLoading, companyState } = useContext(StoreContext);
	const { exportExcelAction } = useExport();

	function findFieldsWithOutNullValues(data) {
		const fieldsWithNullValues = [];

		for (const key in data) {
			if (data[key].value !== null) {
				switch (key) {
					case 'formattedCode':
						fieldsWithNullValues.push({
							searchField: 'Code',
							search: data[key].value,
						});
						break;
					case 'fullName':
						fieldsWithNullValues.push({
							searchField: 'FullName',
							search: data[key].value,
						});
						break;
					case 'jobDepartmentName':
						fieldsWithNullValues.push({
							searchField: 'JobDepartmentName',
							search: data[key].value,
						});
						break;
					case 'jobPositionaName':
						fieldsWithNullValues.push({
							searchField: 'JobPositionName',
							search: data[key].value,
						});
						break;
					case 'periodTypeName':
						fieldsWithNullValues.push({
							searchField: 'PeriodTypeName',
							search: data[key].value,
						});
						break;
					default:
						break;
				}
			}
		}

		return fieldsWithNullValues;
	}
	async function exportExcel() {
		if (filters.length > 0) {
			try {
				const _filter = filterManager.createDataBase64();
				const result = await exportExcelAction({
					orderBy: 'Code',
					orderAsc: false,
					generalSearch: '',
					SpecificFilters: findFieldsWithOutNullValues(filter),
					date: new Date(),
					dataFilters: _filter,
					companyName: companyState?.companies?.name
						? companyState.companies.name
						: '',
				});
				if (result.status === 200) {
					dispatchLoading({ type: LOADING_ON });
					const fillName = getFileNameWithDateTime(
						'Mantenimiento de colaboradores'
					);
					await downloadFileExcel(result, fillName)
						.then(() => {
							dispatchLoading({ type: LOADING_OFF });
						})
						.catch(() => {
							dispatchLoading({ type: LOADING_OFF });
						});
				}
				return result;
			} catch (error) {}
		} else {
			Toast(
				'warning',
				GeneralNames.EmptyDataMessageExport,
				GeneralNames.EmptyDataMessageBodyExport,
				'success'
			);
		}
	}

	function onClickTurn() {
		if (
			selectedCollaborators === undefined ||
			selectedCollaborators.length === 0
		) {
			Toast(
				'warning',
				GeneralNames.GeneralStatusError,
				GeneralNames.EmptyDataCollaborator,
				'success'
			);
		} else {
			setIsMassive(true);
			setOpenWorkShifDialog(true);
		}
	}

	const iconsDataPanel = [
		{ icon: 'turn', title: 'Modificar turno', onClick: onClickTurn },
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick: exportExcel,
		},
	];
	return (
		<div className='flex w-full h-full flex-row'>
			<div className={`flex flex-grow h-full  p-1 pl-4 `}>
				<SearchComponent
					placeholder={placeholder}
					value={value}
					regexName={regexName}
					onChange={onChange}
					clearTextLabel={clearTextLabel}
				/>
			</div>
			<div className='flex w-1/5 h-full p-1'>
				<IconsPanelControl iconsData={iconsDataPanel} />
			</div>
		</div>
	);
};
